















































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'Question431',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
    };
  },
  computed: {
    statements(): string[] {
      return ['a) Li-F', 'b) Si-Br', 'c) O-Cl', 'd) C-S', 'e) H-O', 'f) B-F', 'g) Ga-O'];
    },
  },
});
